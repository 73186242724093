import React from 'react'
import { Root, Routes, Head, withSiteData } from 'react-static'
// import { Link } from '@reach/router'

// import './app.css'

const App = withSiteData(({ title }) => (
    <Root>
      <Head>
        <title>{title}</title>
      </Head>
      <Routes />
    </Root>
  )
)

export default App
