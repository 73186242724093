
import universal, { setHasBabelPlugin } from '/opt/atlassian/pipelines/agent/build/node_modules/react-universal-component/dist/index.js'


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../src/pages/404.js'), universalOptions)
const t_1 = universal(import('../src/pages/AwardRules.js'), universalOptions)
const t_2 = universal(import('../src/pages/index.js'), universalOptions)
const t_3 = universal(import('../src/pages/Parking.js'), universalOptions)
const t_4 = universal(import('../src/pages/Protection.js'), universalOptions)
const t_5 = universal(import('../src/pages/RaceRules.js'), universalOptions)
const t_6 = universal(import('../src/pages/result.js'), universalOptions)
const t_7 = universal(import('../src/pages/Transportations.js'), universalOptions)


// Template Map
export default {
  '../src/pages/404.js': t_0,
'../src/pages/AwardRules.js': t_1,
'../src/pages/index.js': t_2,
'../src/pages/Parking.js': t_3,
'../src/pages/Protection.js': t_4,
'../src/pages/RaceRules.js': t_5,
'../src/pages/result.js': t_6,
'../src/pages/Transportations.js': t_7
}

export const notFoundTemplate = "../src/pages/404.js"
