// Imports


// Plugins
const plugins = [{
  location: "/opt/atlassian/pipelines/agent/build",
  plugins: [],
  hooks: {}
}]

// Export em!
export default plugins